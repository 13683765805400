import React from 'react'
// import loader from './loader';
import { RefObject } from 'react';
import { PlayerState } from 'context/PlayerContext';

interface IAzurePlayerProps {
	source: string;
	duration: number;
	playerState: PlayerState;
	setPositionAudio: React.Dispatch<React.SetStateAction<number>>;
	setDurationAudio: React.Dispatch<React.SetStateAction<number>>;
	manualPosition: number | undefined;
}

declare const window: any;

export default class AppleiOSPlayer extends React.Component<IAzurePlayerProps, any> {
	audioNode: RefObject<any>;
	constructor(props: IAzurePlayerProps) {
		super(props);
		this.audioNode = React.createRef();
	}

	componentWillUnmount() {

		this.audioNode.current.removeEventListener('durationchange', this._updateDuration);
		this.audioNode.current.removeEventListener('timeupdate', this._updatetime);
	}

	componentDidMount() {
		this._setAudio();
		this.audioNode.current.ontimeupdate = this._updatetime.bind(this);
		this.audioNode.current.ondurationchange = this._updateDuration.bind(this)
		
	}

	componentDidUpdate(prevProps: IAzurePlayerProps) {
		if (prevProps.playerState !== this.props.playerState) {
			if (this.props.playerState === PlayerState.playing) {
				this.audioNode.current.play().catch((e: any) => {

					console.log(e);
				});
			}
			if (this.props.playerState === PlayerState.paused) {
				this.audioNode.current.pause();
			}
		}
		if (this.props.manualPosition !== undefined && prevProps.manualPosition !== this.props.manualPosition) {
			try {
				this.audioNode.current.currentTime = Number(this.props.manualPosition);
			} catch (err) {
				console.log('Timer Error: ', err);
			}
		}
		if (prevProps.source !== this.props.source) {
			this._setAudio();
			this.audioNode.current.play()
		}
	}


	_updatetime() {
		try {
			this.props.setPositionAudio(this.audioNode.current.currentTime);
		} catch {

		}
	}

	_fetchAndParseM3U8 = async (url: string) => {
		try {
			const response = await fetch(url);
			const text = await response.text();

			// Parse the m3u8 file line by line
			const lines = text.split("\n");
			var duration = 0;
			for (let line of lines) {
				if (line.startsWith("#EXT-X-TARGETDURATION:")) {
					duration = parseInt(line.split(":")[1], 10); // Extract the duration
					break;
				}
			}
			console.log('parsed duration', duration);
			this.props.setDurationAudio(duration);
		} catch (error) {
			console.error("Error fetching or parsing the .m3u8 file:", error);
		}
	}

	async _updateDuration() {
		try {
			this.props.setDurationAudio(this.props.duration)
		}
		catch (err) {
			console.log("Duration error", err)
		}
	}

	_setAudio() {
		try {
			const appl = this.props.source.replace('(format=mpd-time-csf)', '(format=m3u8-cmaf)')
			//console.log('apple ' + appl);
			this.audioNode.current.src = appl;
			// use this for testing as the stream will now only work in apple ios
			//this.audioNode.current.src = 'https://filesamples.com/samples/audio/mp3/Symphony%20No.6%20(1st%20movement).mp3'

		} catch (err) {
			console.log('Set Episode Error: ', err);
		}
	}

	render() {
		return (
			<div>
				<audio autoPlay={false} ref={this.audioNode} src={this.props.source} />
			</div>
		);
	}
}
