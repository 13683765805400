import React, { useContext, useRef } from 'react';
import AzurePlayer from './AzurePlayer';
import AppleiOSPlayer from './AppleiOSPlayer';


import { ProgrammeContext } from 'context/ProgrammeContext';
import MobilePlayerComponent from './MobilePlayerComponent';
import WebPlayerComponent from './WebPlayerComponent';
import { PlayerContext } from 'context/PlayerContext';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Search } from 'assets/icons/icon-search.svg';
import { ReactComponent as Record } from 'assets/icons/icon-record.svg';
import classNames from 'classnames';
import AndroidPlayer from './AndroidPlayer';

const PlayerComponent: React.FC = () => {
	const programmeContext = useContext(ProgrammeContext);
	const playerContext = useContext(PlayerContext);
	const location = useLocation();
	const searchShowing = location?.pathname.includes('search') ? true : false;
	const { playerLarge, setPlayerLarge } = playerContext;
	const audio = useRef<HTMLAudioElement>(null);
	// Trying to detect iPhone and iPad which both should support HLS.
	// iPad 15 and higher no longer report ipad in the user agent
	// Feature detection: audio.current.canPlayType('application/vnd.apple.mpegurl') seems to cause issues with some android devices (False Positives)
	// So were are testing for either iphone OR safari AND HLS support
	const iOS =
		!navigator.userAgent.match(/Android/) &&
		((navigator.userAgent.match(/ipad|iphone/i)) != null ||
			(navigator.userAgent.match(/safari/i) !== null && (audio.current !== null && audio.current.canPlayType('application/vnd.apple.mpegurl') !== '')));
	const android = navigator.userAgent.match(/Android/);
	return (
		<React.Fragment>
			<WebPlayerComponent />
			<div className="position-sticky bottom-0 link-mobile">
				<MobilePlayerComponent />
				<div className="tab-mobile d-flex align-content-end bg-dark" onClick={() => setPlayerLarge(false)}>
					<Link to="/" className="col-6 py-2 text-center text-decoration-none">
						<Record className={classNames('pb-1 ', searchShowing === true ? 'text-gray' : 'text-white')} />
						<span className={classNames('d-block ', searchShowing === true ? 'text-gray' : 'text-white')}>explore</span>
					</Link>
					<Link to="/search" className="col-6 py-2 text-center text-decoration-none">
						<Search className={classNames('pb-1 ', searchShowing === true ? 'text-white' : 'text-gray')} />
						<span className={classNames('d-block ', searchShowing === true ? 'text-white' : 'text-gray')}>search</span>
					</Link>
				</div>
			</div>
			{/* Player component - hidden */}
			<div className="d-none">
				{/* Using and audio tag for feature detection */}
				<audio style={{ display: 'none' }} ref={audio} />
				{((iOS) ?
					<AppleiOSPlayer
						key={programmeContext?.selectedAudio?.id}	// when the id value changes, it unmounts and remounts the player so the player doesn't fail when switching rapidly on mobile
						source={programmeContext?.selectedAudio?.streamURL || ''}
						duration={programmeContext?.selectedAudio?.duration || 0}
						playerState={playerContext.playerState}
						setPositionAudio={playerContext.setPositionAudio}
						setDurationAudio={playerContext.setDurationAudio}
						manualPosition={playerContext.manualPosition}
					/>
					:
					// (android) ?
						<AndroidPlayer
							key={programmeContext?.selectedAudio?.id}	// when the id value changes, it unmounts and remounts the player so the player doesn't fail when switching rapidly on mobile
							source={programmeContext?.selectedAudio?.mp3Stream || ''}
							duration={programmeContext?.selectedAudio?.duration || 0}
							playerState={playerContext.playerState}
							setPositionAudio={playerContext.setPositionAudio}
							setDurationAudio={playerContext.setDurationAudio}
							manualPosition={playerContext.manualPosition}
						/>
						// :
						// <AzurePlayer
						// 	key={programmeContext?.selectedAudio?.id}	// when the id value changes, it unmounts and remounts the player so the player doesn't fail when switching rapidly on mobile
						// 	source={programmeContext?.selectedAudio?.streamURL || ''}
						// 	playerState={playerContext.playerState}
						// 	setPositionAudio={playerContext.setPositionAudio}
						// 	setDurationAudio={playerContext.setDurationAudio}
						// 	manualPosition={playerContext.manualPosition}
						// />
				)}

			</div>
		</React.Fragment>
	);
}

export default PlayerComponent;
