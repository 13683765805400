import useEffectAsync from 'components/customHooks/useEffectAsync';
import { IPlayListData } from 'components/player/PlayList';
import moment from 'moment';
import getCKUAData from 'playerapi/ckuaAPI';
import React, { createContext, useContext } from 'react';
import { useEffect, useLayoutEffect } from 'react';
import ReactGA from 'react-ga4';
import { PlayerContext, PlayerState } from './PlayerContext';

//TODO - Add context for Featured Shows, Recently Added, Expiring Soon, Featured Hosts, etc
interface IProgrammeContext {
	isLoading: boolean;
	allProgrammes?: CKUADataDTO;
	selectedAudio?: AudioView;
	updateSelectedAudio: (cardId: string, segment: AudioView) => void;
	featuredProgramme?: SegmentView[];
	featuredHost?: HostView[];
	recentlyAddedProgrammes?: ProgrammeView[];
	expiringSoonProgrammes?: ProgrammeView[];
	hosts?: HostView[];
	programmes?: ProgrammeView[];
	segments?: SegmentView[];
}

// type audioType = 'segment' | 'host'
interface AudioView {
	id?: number;
	streamURL?: string;
	duration?: number;
	name?: string;
	description?: string;
	image?: string;
	filename?: string;
	programme?: ProgrammeView;
	hostName?: string;
	airDate?: string;
	playlistSong?: IPlayListData[];
	featured?: boolean;
	expiringSoon?: boolean;
	recentlyAdded?: boolean;
	mp3Stream?: string;
	// audioType: audioType;
}



const ProgrammeContext = createContext<IProgrammeContext>({} as any);

const ProgrammeProvider = (props: any) => {
	const playerContext = useContext(PlayerContext);
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [allData, setAllData] = React.useState<CKUADataDTO | undefined>(undefined);
	const [hosts, setHosts] = React.useState<HostView[]>([]);
	const [programmes, setProgrammes] = React.useState<ProgrammeView[]>([]);
	const [segments, setSegments] = React.useState<SegmentView[]>([]);
	const [expiringSoon, setExpiringSoon] = React.useState<SegmentView[]>([]);
	const [recentlyAdded, setRecentlyAdded] = React.useState<SegmentView[]>([]);
	const [selectedAudio, setSelectedAudio] = React.useState<AudioView | undefined>(undefined);

	useEffectAsync(async (isMounted: any) => {
		onLoad(isMounted);
	});
	useLayoutEffect(() => {
		document.addEventListener('visibilitychange', onVisibilityChange);
		return () => document.removeEventListener('visibilitychange', onVisibilityChange);
	}, []);
	return (
		<ProgrammeContext.Provider
			value={{
				isLoading,
				allProgrammes: allData,
				selectedAudio: selectedAudio,
				updateSelectedAudio: updateSelectedAudio,
				featuredProgramme: getFeaturedShows(programmes),
				featuredHost: getFeaturedHosts(hosts),
				expiringSoonProgrammes: expiringSoon,
				recentlyAddedProgrammes: recentlyAdded,
				hosts,
				programmes,
				segments
			}}
		>
			{props.children}
		</ProgrammeContext.Provider>
	)

	//TODO - Check if isMounted needed?
	async function onLoad(isMounted: any) {
		setIsLoading(true);
		const allNewData = await getCKUAData();
		if (isMounted()) {
			setAllData(allNewData);
			setIsLoading(false);
		}

		if (allNewData.segments !== undefined) {
			//console.log('hosts', allNewData.hosts);
			const validSegments = allNewData.segments.filter((item: any) => item.streamURL !== undefined);
			setSegments(validSegments);
			setExpiringSoon(getExpiringSoon(validSegments));
			setRecentlyAdded(getRecentlyAdded(validSegments));
		}
		if (allNewData.hosts !== undefined && allNewData.hosts?.length > 0) {
			setHosts(allNewData.hosts);
		}
		if (allNewData.programmes !== undefined && allNewData.programmes?.length > 0) {
			setProgrammes(allNewData.programmes)
		}

		initateFirstFeaturedSegment(allNewData);


	}
	function onVisibilityChange() {
		if (document.visibilityState === 'visible') {
			getCKUAData();
		}
	};
	//Toggles the play/pause if segment already set to card.  Otherwise updates segment to new card and auto plays.
	function updateSelectedAudio(cardId: string, segment: AudioView) {
		// Safari (iOS & MAC) needs playerContext.activeCardId === undefined to handle the first click situation, not sure why but this fixes it remove at your peril
		if (selectedAudio && segment.id === selectedAudio.id) {
			playerContext.togglePlayPause();
		} else {
			playerContext.setPlayerState(PlayerState.paused);  //Pause required so autoplay can trigger
			setSelectedAudio(segment);

			playerContext.setActiveCardId(cardId);
			ReactGA.gtag('event', 'Player', {
				segment: segment.id,
				programme: segment.programme || '',
				host: segment.hostName || segment.name,
				airDate: segment.airDate,
				featured: segment.featured || false,
				recentlyAdded: segment.recentlyAdded || false,
				expiringSoon: segment.expiringSoon || false,
				hostAudio: !segment.id && true
			})
			// scroll to top for playlist
			const eleWebs = document.getElementsByClassName('player-2')
			if (eleWebs.length > 0) {
				eleWebs[0].scrollTo({
					top: 0,
					behavior: 'smooth'
				})
			};
			// set and reset overflow to scroll mobile player to top, as cannot change scroll pos when overflow:hidden
			// const ele = document.getElementById('mobile-player');
			// if (ele) {
			// 	ele.classList.add('overflow-auto');
			// 	ele.scrollTo({
			// 		top: 0
			// 	});
			// 	ele.classList.remove('overflow-auto');
			// }
		}
	}

	function initateFirstFeaturedSegment(showData: CKUADataDTO) {
		if (showData.programmes !== undefined && showData.programmes?.length > 0 && showData.segments !== undefined && showData.segments?.length > 0) {
			const featuredProgrammes = showData.programmes.filter((item) => (item.featured === true));
			featuredProgrammes.every((programme) => {
				const matchedSegments = showData.segments?.filter((segment) => (segment.programmeId === programme.id)).sort(
					(a, b) => (moment.utc(a.expiryDate).diff(moment.utc(b.expiryDate)))
				)
				if (matchedSegments !== undefined && matchedSegments.length > 0) {
					const matchedSegment = matchedSegments[0]
					setSelectedAudio(matchedSegment);
					// if(matchedSegment.id){
					// 	playerContext.setActiveCardId(matchedSegment.id+'');
					// }
					return false;
				} else {
					return true;
				}
			})
		}
	}

	function getExpiringSoon(segments: SegmentView[]) {
		const segmentsExpiringSoon = segments?.sort(
			(a, b) => (moment.utc(a.expiryDate).diff(moment.utc(b.expiryDate)))
		).slice(0, 4);
		return segmentsExpiringSoon.map(item => ({ ...item, expiringSoon: true }));
	}

	function getRecentlyAdded(segments: SegmentView[]) {
		const segmentsRecentlyAdded = segments?.filter((item) => moment.utc(item.airDate).diff(moment()) < 0).sort(
			(a, b) => (moment.utc(b.airDate).diff(moment()) - moment.utc(a.airDate).diff(moment()))
		).slice(0, 4);
		return segmentsRecentlyAdded.map(item => ({ ...item, recentlyAdded: true }));
	}

	function getFeaturedHosts(hosts: HostView[]): HostView[] {
		const featuredHosts = hosts.filter((item) => (item.featured === true)).slice(0, 3);
		return featuredHosts;
	}

	function getFeaturedShows(programmes: ProgrammeView[]): SegmentView[] {
		const featuredProgrammes = programmes.filter((item) => (item.featured === true));
		const featuredShowsSegments: SegmentView[] = []
		featuredProgrammes.forEach((programme) => {
			let matchedSegments = segments.filter((segment) => (segment.programmeId === programme.id));
			if (matchedSegments.length > 0) {
				const matchedSegment = { ...matchedSegments[0], featured: true }
				featuredShowsSegments.push(matchedSegment);
			}
		});
		return featuredShowsSegments.slice(0, 3);
	}
}

export {
	ProgrammeContext,
};

export default ProgrammeProvider;